import { createApp , onErrorCaptured } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './locale'
// import '@/assets/css/reset.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'animate.css'
import infiniteScroll from 'vue3-infinite-scroll-better'

createApp(App).use(store).use(router).use(i18n).use(Vant).use(infiniteScroll).mount('#app');