import hall_h from './EN/hall-h';
import hall_s from './EN/hall-s';




export default {
  ...hall_h,
  ...hall_s
};
