import hall_h from './ID/hall-h';
import hall_s from './ID/hall-s';




export default {
  ...hall_h,
  ...hall_s
};
