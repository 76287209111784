import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { i18n } from '@/locale/index';



const { t } = i18n.global;



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/hall-h',
    name: 'hall-h',
    component: () => import('../views/hall-h/index.vue'),
  },
  {
    path: '/hall-s',
    name: 'hall-s',
    component: () => import('../views/hall-s/index.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/notFound/notfound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name:'notfound',
    redirect:'/404'
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to:any, from:any) => {
  document.title = to.meta.title ? to.meta.title : 'hall';
})

export default router
