import hall_h from './JA/hall-h';
import hall_s from './JA/hall-s';




export default {
  ...hall_h,
  ...hall_s
};
