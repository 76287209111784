import hall_h from './CN/hall-h';
import hall_s from './CN/hall-s';




export default {
  ...hall_h,
  ...hall_s
};
