import hall_h from './VN/hall-h';
import hall_s from './VN/hall-s';




export default {
  ...hall_h,
  ...hall_s
};
