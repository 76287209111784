import { ref } from "@vue/reactivity";
import { getJsUrl } from '@/utils/function';
import { createI18n } from 'vue-i18n';
import CN from './CN';
import EN from './EN';
import VN from './VN';
import PT from './PT';
import JA from './JA';
import TH from './TH';
import RU from './RU';
import ID from './ID';
import MS from './MS';

var route:any;

if(location.href.indexOf('?') !== -1){
    route = getJsUrl(location.href);
}

if(route && route.language){
    sessionStorage.setItem('locale-language',route.language);
}else{
    const country = sessionStorage.getItem('locale-language');
    country ? sessionStorage.setItem('locale-language',country) : sessionStorage.setItem('locale-language','en');
}



export const LOCALE_OPTIONS = [
    { label: '中文', value: 'cn' },
    { label: '印尼', value: 'id' },
    { label: '印度', value: 'en' },
    { label: '俄国', value: 'ru' },
    { label: '美国', value: 'en' },
    { label: '泰国', value: 'th' },
    { label: '马来西亚', value: 'ms' },
    { label: '日本', value: 'ja' },
    { label: '巴西', value: 'pt' },
    { label: '菲律宾', value: 'en' },
    { label: '越南', value: 'vn' },
];

const defaultLocale = ref(sessionStorage.getItem('locale-language') ? sessionStorage.getItem('locale-language') : route.language ? route.language : 'cn');

const i18n = createI18n({
    locale: defaultLocale.value,
    fallbackLocale: 'en',
    allowComposition: true,
    messages: {
        cn:CN,
        en:EN,
        vn:VN,
        pt:PT,
        ja:JA,
        th:TH,
        ru:RU,
        id:ID,
        ms:MS
    }
});
  
export {
    defaultLocale,
    i18n
};